import { Link } from "react-router-dom";
import MenuMobile from "./MenuMobile";

export default function Header(props){

    function pagePreview(logo){
        const logoUrl="./img/svg/"+logo;
        const img=document.getElementById("header-title-img");
        img.className="header-anim-unload";
        setTimeout(()=>{
             img.className="header-anim-load"; 
             img.src=logoUrl;
        }, 300);
    }
    function headerAnim(tab, translate){
        document.getElementById("header-list").getElementsByTagName("img")[tab].style.transform="translateY("+translate+")";
    }

    const currentPage ="./img/svg/"+props.title; 
    return(
        <div id="header-cont">
            <MenuMobile onClick={props.onClick}/>
            <nav id="header-gallery">
                <ul id="header-list">  
                    <li onMouseEnter={()=>{headerAnim(0, "-10px");}}
                        onMouseLeave={()=>{headerAnim(0, "0px");}}>
                        <Link 
                        onClick={()=>{props.onClick();}}
                        to="/scolaire">
                            <img 
                            onMouseEnter={() => {pagePreview("Item_scolaire.svg");}}
                            onMouseLeave={() => {pagePreview(props.title);}}
                            src="./img/svg/nav_virgule_scolaire.svg" />
                            </Link>
                    </li>
                    <li onMouseEnter={()=>{headerAnim(1, "-10px");}}
                        onMouseLeave={()=>{headerAnim(1, "0px");}}>
                        <Link 
                        onClick={()=>{props.onClick();}}
                        to="/edition">
                            <img 
                            onMouseEnter={() => {pagePreview("Item_edition.svg");}}
                            onMouseLeave={() => {pagePreview(props.title);}}
                            src="./img/svg/nav_virgule_edition.svg" />
                        </Link>
                    </li>
                    <li onMouseEnter={()=>{headerAnim(2, "-10px");}}
                        onMouseLeave={()=>{headerAnim(2, "0px");}}>
                        <Link 
                        onClick={()=>{props.onClick();}}
                        to="/communication">
                            <img 
                            onMouseEnter={() => {pagePreview("Item_com.svg");}}
                            onMouseLeave={() => {pagePreview(props.title);}}
                            src="./img/svg/nav_virgule_com.svg" />
                            </Link>
                    </li>
                    <li onMouseEnter={()=>{headerAnim(3, "-10px");}}
                        onMouseLeave={()=>{headerAnim(3, "0px");}}>
                        <Link 
                         onClick={()=>{props.onClick();}}
                        to="/identite">
                            <img 
                             onMouseEnter={() => {pagePreview("Item_identite.svg");}}
                             onMouseLeave={() => {pagePreview(props.title);}}
                            src="./img/svg/nav_virgule_identite.svg" />
                            </Link>
                    </li>
                    <li onMouseEnter={()=>{headerAnim(4, "-10px");}}
                        onMouseLeave={()=>{headerAnim(4, "0px");}}>
                        <Link 
                         onClick={()=>{props.onClick();}}
                        to="/image">
                            <img 
                             onMouseEnter={() => {pagePreview("Item_image.svg");}}
                             onMouseLeave={() => {pagePreview(props.title);}}
                            src="./img/svg/nav_virgule_image.svg" />
                            </Link>
                    </li>
                    <li onMouseEnter={()=>{headerAnim(5, "-10px");}}
                        onMouseLeave={()=>{headerAnim(5, "0px");}}>
                        <Link 
                         onClick={()=>{props.onClick();}}
                        to="/web">
                            <img 
                             onMouseEnter={() => {pagePreview("Item_web.svg");}}
                             onMouseLeave={() => {pagePreview(props.title);}}
                            src="./img/svg/nav_virgule_web.svg" />
                            </Link>
                    </li>
                </ul>
            </nav>
            <div id="header-title">
                <img id="header-title-img" src={currentPage}/>
            </div>
        </div>
    );
}