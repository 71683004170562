import ModalButton from "./ModalButton";
import APropos from "./AProposComp";
import ContactForm from "./ContactForm";


export default function FooterHome(){
    return(
        <div id="footer-cont">
            <div id="footer-void"></div>
            <ModalButton gif="./img/svg/Apropos.gif" img="./img/svg/Apropos.svg" id="apropos" content={<APropos />}/>
            <ModalButton gif="./img/svg/Contact.gif" img="./img/svg/Contact.svg" id="contact" content={<ContactForm />}/>
        </div>
    );
}