import React, {useEffect, useState, useLayoutEffect } from "react";
import FooterGallery from "./FooterGallery";
import Header from "./Header";
import { useSwipeable } from "react-swipeable";
import axios from 'axios';
import baseURL from "../js/baseURL";

export default function Gallery(props){
   // const data = require("../../public/data/"+props.list+".json");
   const [data, setData] = useState();
    const [desc, setDesc] = useState();
    const[slides, setSlides]=useState();
    const[headerClick, setHeaderClick] = useState(false);
    const [numSlides, setNumSlides] = useState();
    //const numSlides = Object.keys(data).length;
    const [slideDiv, setSlideDiv] = useState();
    //(1/numSlides)*100; 
    const [slideContWidth, setSlideContWidth] = useState(); 
   // const slideContWidth = (numSlides*100)+"%";

/***********INIT*******************/

    useEffect(()=>{
        const urlDev = baseURL+"/data/data.php?category="+props.list;
        const url = "/data/data.php?category="+props.list;
        axios.get(url).then((res)=>{
            //console.log(res);
            setData(res.data);
            //console.log(res.data);
        }).catch(()=>{
            console.log("erreur AXIOS");
        });
    },[]);

    useEffect(()=>{
        if(typeof data !== 'undefined'){
            setNumSlides(Object.keys(data).length);
            initSlides();
            initDesc();
            legendHeight();

            setTimeout(()=>{
                //document.getElementById("gallery-cont").className="play-load-animation";
                const images = document.getElementById("slide-cont").getElementsByTagName("img");
                for(let i = 0; i < images.length; i++ ){
                    images[i].className="img-loaded";
                }
            }, 300);

            if(headerClick){
                setHeaderClick(false);
            }
       } 
    },[data, headerClick]);  

    useEffect(()=>{
        setSlideDiv((1/numSlides)*100);
        setSlideContWidth((numSlides*100)+"%");
    }, [numSlides]);

    function imgLoaded(e){
        const target = e.target;
        //target.classList.remove("img-loaded");
        if(target.complete){
            setTimeout(
                    () => {
                        target.classList.add("img-loaded");
                    },500);
        }
    }
   
    function initSlides(){
        //console.log(data);
        const mySlides = data.map((slide) => {
             return(
             <div className="slide">
                     <img key={slide.id.toString()} src={slide.src} onLoad={(e)=>{imgLoaded(e)}} />
             </div>
             );
         });
     setSlides(mySlides);
    }
    function legendHeight(){
        const legendes = document.getElementsByClassName("legend-content");
        let maxHeight = 0;
        //console.log("num legendes : "+legendes.length);
        for(let i=0; i<legendes.length; i++){
            const legendStyle = window.getComputedStyle(legendes[i]);
           // console.log("legend "+i+" height :"+legendStyle.height);
            if(legendes[i].style.height > maxHeight){
                maxHeight =legendes[i].style.height;
            }
        }

        //console.log("height : " + maxHeight);

    }
    function initDesc(){
        const init = data.map((slide)=>{
            const display = (slide.id === 0)? "flex" : "none";
            const styleAnchor={
                backgroundColor : props.accentColor,
            };
            const styleSpan={
                color : props.accentColor,
                backgroundColor : "white",
            };

            const linkPdf = () =>{
                if(slide.pdf === ""){
                    return;
                }
                else{
                return (
                    <a href={slide.pdf} style={styleAnchor} download>&nbsp;Télécharger le&nbsp;&nbsp;<span style={styleSpan}>PDF</span></a>
                );
                }
            }

            const hasTitle2 = () =>{
                if(slide.hasOwnProperty('title2')){
                    return(<h2>{slide.title2}</h2>);
                }
                else{ return; }
            }
            const hasDesc2 = () =>{
                if(slide.hasOwnProperty('desc2')){
                    return(<p>{slide.desc2}</p>);
                }
                else{ return; }
            }
            return(
                <div key={slide.id.toString()} className="legend"
                    style={{display : display}}>
                    <div className="legend-content">
                        <h2>{slide.title}</h2>
                        <p>{slide.desc}</p>
                        {hasTitle2()}
                        {hasDesc2()}
                    </div>
                    {linkPdf()}
                </div>
            )
        });
        setDesc(init);
        
    }
 /***************SLIDE NAVIGATION********************/
     const[currentSlide, setCurrentSlide] = useState(0);
     const[previousSlide, setPreviousSlide] = useState(0);
     const handlers = useSwipeable({
         onSwipedLeft: () => nextSlide(),
         onSwipedRight: () => prevSlide(),
         preventDefaultTouchmoveEvent: true,
         trackMouse: true
       });
 
     function nextSlide(){
         setPreviousSlide(currentSlide);
         setCurrentSlide(currentSlide+1);
         if(currentSlide>=(numSlides-1)){
             setCurrentSlide(0);
         }
         
     }
 
     function prevSlide(){
        setPreviousSlide(currentSlide);
        setCurrentSlide(currentSlide-1);
        if(currentSlide === 0){
            setCurrentSlide(numSlides-1);
        }
       
     }
 
     function resetSlides(){
       // document.getElementById("gallery-cont").className="not-loaded play-unload-animation";
        //setTimeout(()=>{ console.log("wait");}, 1000);
        const images = document.getElementById("slide-cont").getElementsByTagName("img");
        for(let i = 0; i < images.length; i++ ){
            images[i].className="img-unload";
        }
      /*  document.getElementsByClassName("img-loaded").forEach(img => {
            img.className="img-unload";
        });*/
         setCurrentSlide(0);
         setPreviousSlide(0);
         setHeaderClick(true);
     }
/* *******************UPDATE************* */
useEffect(()=>{
    //console.log(slideDiv);
    const legendes=document.getElementsByClassName("legend");

    document.getElementById("slide-cont").style.transform="translateX(-"+(currentSlide*slideDiv)+"%)";

    for(let i=0; i<legendes.length; i++){
        if(i === currentSlide){
            legendes[currentSlide].style.display="flex";
            }
        else{legendes[i].style.display="none";}
    }

}, [currentSlide, slideDiv]);
/**************RENDER****************/

    return(
        <div id="page-cont">
        <Header onClick={resetSlides} title={props.titleImg}/>
        <div id="gallery-cont" className="play-load-animation">
                <div id="slideshow" {...handlers}>
                        <div id="slide-cont" style={{width : slideContWidth}}>
                            {slides}
                        </div>
                        <button 
                            onClick={() => {nextSlide();}}
                            id="next-slide">
                                <img src="./img/svg/galerie_fleche_d.svg"/>
                            </button>
                        <button 
                            onClick={()=>{prevSlide();}}
                        id="prev-slide">
                            <img src="./img/svg/galerie_fleche_g.svg"/>
                        </button>
                </div>
                    <div id="slide-desc">
                        <div id="legend-cont">
                            {desc}
                        </div>
                    </div>
            </div>
        <FooterGallery />
      </div>
    );
}