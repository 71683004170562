import { Link } from "react-router-dom";
import ModalButton from "./ModalButton";
import APropos from "./AProposComp";
import ContactForm from "./ContactForm";

export default function FooterGallery(){
    return(
        <div id="footer-gal-cont">
            <div id="gal-footer-void"></div>
            <ModalButton gif="./img/svg/Apropos.gif" img="./img/svg/Apropos.svg" id="gal-apropos" content={<APropos />}/>
            <ModalButton gif="./img/svg/Contact.gif" img="./img/svg/Contact.svg" id="gal-contact" content={<ContactForm />} />
            <div id="gal-logo">
                <Link 
                onMouseEnter={() => {
                    document.getElementById("gal-logo-img").style.transform="translateY(5px)";
                }}
                onMouseLeave={() => {
                    document.getElementById("gal-logo-img").style.transform="translateY(0%)";
                }}
                to="/">
                    <img id="gal-logo-img" src="./img/svg/logo_footer.svg" />
                </Link>
            </div>
        </div>
    );
}