import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
  } from "react-router-dom";

  import Homepage from "./HomepageComponent";
  import Gallery from "./Gallery";
  import ContactForm from "./ContactForm";
  import APropos from "./AProposComp";
function Home(){
    return(
        <Router>
            <Routes>
                <Route index element={<Homepage />} />
                <Route path="/scolaire" element={<Gallery key="1" accentColor="#DBD921" titleImg="Item_scolaire.svg" list="scolaire"/> } />
                <Route path="/edition" element={<Gallery key="2" accentColor="#B0D64A"titleImg="Item_edition.svg" list="edition"/> } />
                <Route path="/communication" element={<Gallery key="3" accentColor="#82D173" titleImg="Item_com.svg" list="communication"/> } />
                <Route path="/identite" element={<Gallery key="4" accentColor="#59CF9E" titleImg="Item_identite.svg" list="identite"/> } />
                <Route path="/image" element={<Gallery key="5" accentColor="#2BCCC7" titleImg="Item_image.svg" list="image" /> } />
                <Route path="/web" element={<Gallery key="6" accentColor="#00C7F0" titleImg="Item_web.svg" list="web"/> } />
                <Route path="/contact" element={<ContactForm />} />
                <Route path="/a-propos" element={<APropos />} />
            </Routes>
        </Router>
    );
}

export default Home;

