const apropos = {
    "main" : "C’est aux Arts Déco que j’ai découvert les possibilités expressives " +
    "de la typographie et de la mise en pages. Un domaine que " +
    "je n’ai cessé d’approfondir au travers de mes expériences " +
    "professionnelles : d’abord comme directeur artistique au sein " +
    "d’agences de communication, puis comme indépendant au service " +
    "de l’édition, et de la communication culturelle et corporate.\n" +
    "Mon approche graphique cultive la clarté, l’efficacité et le " +
    "pragmatisme. Bien souvent, les réponses sont dans les questions : " +
    "une bonne écoute d’un brief est le meilleur moyen d’accéder à une " +
    "solution graphiquement pertinente.\n" +
    "Ensuite, la maîtrise des fondamentaux (typographie, couleur, " +
    "iconographie) et de la technique (logiciels, prépresse) permet une \"" +
    "finalisation en douceur des projets.\" ",
    "pdf" : "./pdf/CV_FredJely.pdf",
    "adresse" : "42 RUE JEAN NICOLAS LEVEAU 95480 PIERRELAYE",
    "fixe" : "01 30 37 67 01",
    "mobile" : "06 83 95 82 00",
    "site": "https://www.lucien-jely.fr",
};

export default apropos;