import React, {useState} from "react";
import apropos from "../js/apropos";
import MenuMobile from "./MenuMobile";
import axios, { Axios } from "axios";
import FormData from "form-data";
import baseURL from "../js/baseURL";

export default function ContactForm(){
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [societe, setSociete] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail]=useState('');
    const [question, setQuestion]= useState('');

    const[alertnum, setAlert]=useState(1);

    const API_PATH = baseURL+'/api/contact.php';

    const handleSubmit = (e)=>{
        e.preventDefault();
        e.stopPropagation();

        const data = {
            nom : nom,
            prenom : prenom,
            societe : societe,
            tel : tel,
            email : email,
            question : question
        }

        const validateEmail = String(data.email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if((data.nom==="") || (data.prenom==="") || !validateEmail || (data.question==="")){
        if(data.nom===""){document.getElementById("form-nom").classList.add("error");}
            else{
                if(document.getElementById("form-nom").classList.contains("error")){
                     document.getElementById("form-nom").classList.remove("error");
                }
            }
        if(data.prenom===""){document.getElementById("form-prenom").classList.add("error");}
            else{
                if(document.getElementById("form-prenom").classList.contains("error")){
                     document.getElementById("form-prenom").classList.remove("error");
                }
            }
        if(!validateEmail){document.getElementById("form-email").classList.add("error");}
            else{
                if(document.getElementById("form-email").classList.contains("error")){
                     document.getElementById("form-email").classList.remove("error");
                }
            }
        if(data.question===""){document.getElementById("form-question").classList.add("error");}
            else{
                if(document.getElementById("form-question").classList.contains("error")){
                     document.getElementById("form-question").classList.remove("error");
                }
            }
    }
    else{
       axios({
        method: 'post',
        url: API_PATH,
        headers: {
            'content-type': 'application/json',
            'Accept': 'application/json'
        },
        data: data
       }).then(()=>{
           setNom('');
           setPrenom('');
           setSociete('');
           setTel('');
           setEmail('');
           setQuestion('');
           if(document.getElementById("form-nom").classList.contains("error")){
                document.getElementById("form-nom").classList.remove("error");
            }
           if(document.getElementById("form-prenom").classList.contains("error")){
                document.getElementById("form-prenom").classList.remove("error");
             }
           if(document.getElementById("form-email").classList.contains("error")){
                document.getElementById("form-email").classList.remove("error");
             }
           if(document.getElementById("form-question").classList.contains("error")){
                 document.getElementById("form-question").classList.remove("error");
             }
       });
    }
}

    return(
        <div id="form-outerbg">
            <div id="form-innerbg">
                <MenuMobile />
                <img id="form-logo" src="./img/svg/Logo_couleur.svg" />
                <span id="form-title">Contact</span>
                <div id="form-cont">
                    <div id="form-intro">
                        <h2>UNE QUESTION ?</h2>
                        <p>Pour toute demande de renseignement,<br />
                        vous pouvez utiliser ce formulaire ou me joindre
                        directement.</p>
                    </div>
                    <div name="contact-form" >
                        <label htmlFor="form-nom">Nom</label>
                        <input type="text" required name="nom" id="form-nom"
                        value={nom} onChange={e => setNom(e.target.value)}/>

                        <label htmlFor="form-prenom">Prénom</label>
                        <input type="text" required name="prenom" id="form-prenom"
                        value={prenom} onChange={e => setPrenom(e.target.value)}/>

                        <label htmlFor="form-societe">Société</label>
                        <input type="text" name="societe" id="form-societe"
                        value={societe} onChange={e => setSociete(e.target.value)}/>

                        <label htmlFor="form-tel">Téléphone</label>
                        <input type="tel" name="telephone" id="form-tel"
                        value={tel} onChange={e=>setTel(e.target.value)}/>

                        <label htmlFor="form-email">Courriel</label>
                        <input type="email" required name="email" id="form-email"
                        value={email} onChange={e=>setEmail(e.target.value)}/>

                        <div id="question-cont">
                            <label id="question-label" htmlFor="form-question">Votre question</label>
                            <textarea name="question" required id="form-question"
                            value={question} onChange={e=>setQuestion(e.target.value)}/>

                            <label id="submit-button">
                                <img id="submit-img" src="./img/svg/contact_avionfonce.svg"></img>
                                <input value="ENVOYER !" onClick={(e)=>handleSubmit(e)} id="submit-input" type="submit"></input>
                            </label>
                        </div>
                    </div>
                </div>
                <p id="form-contact">{apropos.adresse} / {apropos.fixe} / <span>{apropos.mobile}</span></p>

            </div>
        </div>
    );
}