import React from "react";
import apropos from "../js/apropos";
import MenuMobile from "./MenuMobile";

export default function APropos(){
    return(
        <div>
            <div id="apropos-innerbg">
                <MenuMobile />
                <img id="apropos-portrait" src="./img/apropos_portrait.png" />
                <span id="apropos-title">à Propos</span>
                <div id="apropos-main">
                    <p>{apropos.main}</p>
                    <div id="apropos-lien">
                        <a id="apropos-cv" download href={apropos.pdf}><p>Télécharger le</p> <span>CV</span></a>
                        <p id="apropos-site">Développement du site : <a target="_blank" href={apropos.site}>Lucien Jély</a></p>
                    </div>
                </div>
                <p id="apropos-contact">{apropos.adresse} / {apropos.fixe} / <span>{apropos.mobile}</span></p>
            </div>
        </div>
    );
}