import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './css/homeAnimation.css'
import '../src/css/header.css';
import '../src/css/footer.css';
import '../src/css/footer-gallery.css';
import '../src/css/gallery.css';
import '../src/css/modal.css'
import '../src/css/mobile.css'
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

